// Copyright 2019 Bik_krl
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES";
export const EMPLOYEE_LOADING = "EMPLOYEE_LOADING";
export const RESET_EMPLOYEE_MESSAGE = "RESET_EMPLOYEE_MESSAGE";

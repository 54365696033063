// Copyright 2019 Bik_krl
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export const RANK_MODAL = "RankModal";
export const CREATE_RANK = "CREATE_RANK";
export const RANK_LOADING = "RANK_LOADING";
export const GET_ALL_RANKS = "GET_ALL_RANKS";
export const UPDATE_RANK = "UPDATE_RANK";
export const DELETE_RANK = "DELETE_RANK";
export const RESET_RANK_MESSAGE = "RESET_RANK_MESSAGE";

import React, { Component } from "react";
import CardInfo from "./CardInfo";

export default class Dashboard extends Component {
  render() {
    return (
      <div>
       <CardInfo/>
      </div>
    );
  }
}
